import React from 'react'
import { ImgProvider } from '../../Assest/ImgProvider'
import { Link } from 'react-router'

export default function AppSection() {
  return (
    <div id="app"  >
      <div className=" max-w-7xl px-3 mt-10  mx-auto   md:px-4 ">
        <div className="grid gap-3  text-lg   md:grid-cols-12">
          <div className="space-y-3 col-span-8">
            <div className="relative transition-all   rounded-xl ">
              <div className="w-full  text-left cursor-pointer">
                <div className="flex items-center  ">
                  <img src={ImgProvider?.App1} alt='about' />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-span-4 px-2 flexweb1 ">
            <div className="flex   text-3xl subhedting1">Our App is Available For Android </div>
            <div className="relative transition-all duration-700  rounded-xl">
              <div className=" text-left cursor-pointer">
                <div className="flex mt-10 items-center justify-between textfontll">
                  Our app is available for both Android , giving you access to on-demand yoga,meditation, and wellness resources. Whether you're at home or on the go, enjoy personalized guidance, track your progress, and achieve your wellness goals effortlessly.  </div>
              </div>
              <div className=" mt-5 mb-5 items-center  ">
                {/* <img src={ImgProvider?.iosapp} alt='about' className='appidlo col-span-6' /> */}
              <Link to ="https://play.google.com/store/apps/details?id=yogarise.wellness.yoga" > <img src={ImgProvider?.mobileapp} alt='about' className='appidlo col-span-6 w-[150px]' /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
